<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.push({ name: 'VariableEarnings' })"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Variable Earnings
        </h1>
      </div>
      <div class="w-7/12 mt-4"></div>
    </div>
    <div class="px-3">
      <card class="p-5 mt-6">
        <div class="flex flex-col">
          <div class="flex">
            <div
              class="flex mb-6 w-8/12 flex-grow"
              style="justify-content: flex-start; padding: 4px"
            >
              <span class="text-sm font-semibold text-gray-300 uppercase">
                Employee:
              </span>
              <span
                class="text-sm font-semibold text-darkPurple uppercase ml-1"
              >
                {{ `${employeeData.fname} ${employeeData.lname}` }}
              </span>
            </div>
            <div class="flex mb-6" style="justify-content: flex-end">
              <Button class="text-white" 
                :background="appearance.buttonColor || defaultBTNColor"
                @click="processPay()"
              >
                Process
              </Button>
            </div>
          </div>
          <div class="w-full flex">
            <img
              class="mr-2 my-5"
              style="width: 118px; height: 118px; border-radius: 5px"
              alt="photo"
              :src="employeeData.photo || account"
              v-if="employeeData.photo"
            />
            <div
              style="height: 35px; width: 35px; border-radius: 5px"
              class="text-blueCrayola border text-center font-semibold pt-2"
              v-else
            >
              {{ $getInitials(`${employeeData.fname} ${employeeData.lname}`) }}
            </div>
            <div class="flex flex-wrap w-full">
              <card
                class="p-3 ml-5 mr-2 mt-5 mb-5"
                style="width: 32%; height: 55px"
              >
                <div class="w-full flex h-auto">
                  <icon
                    icon-name="icon-suitcase"
                    size="xs"
                    class="mr-1 -mt-6 text-romanSilver"
                  />
                  <div class="w-full flex flex-col h-auto">
                    <div
                      class="uppercase font-semibold text-blueCrayola"
                      style="font-size: 10px"
                    >
                      Designation
                    </div>
                    <div
                      class="w-full text-sm uppercase font-semibold text-jet"
                    >
                      {{
                        employeeData.userDesignation
                          ? employeeData.userDesignation.name
                          : "None"
                      }}
                    </div>
                  </div>
                </div>
              </card>
              <card class="p-3 mr-2 mt-5" style="width: 32%; height: 55px">
                <div class="w-full flex h-auto">
                  <icon
                    icon-name="icon-department"
                    size="xs"
                    class="mr-1 -mt-6 text-romanSilver"
                  />
                  <div class="w-full flex flex-col h-auto">
                    <div
                      class="uppercase font-semibold text-blueCrayola"
                      style="font-size: 10px"
                    >
                      Function
                    </div>
                    <div
                      class="w-full text-sm uppercase font-semibold text-jet"
                    >
                      {{
                        employeeData.orgFunction
                          ? employeeData.orgFunction.name
                          : "None"
                      }}
                    </div>
                  </div>
                </div>
              </card>
              <card class="p-3 mt-5" style="width: 32%; height: 55px">
                <div class="w-full flex h-auto">
                  <icon
                    icon-name="icon-manager"
                    size="xs"
                    class="mr-1 -mt-6 text-romanSilver"
                  />
                  <div class="w-full flex flex-col h-auto">
                    <div
                      class="uppercase font-semibold text-blueCrayola"
                      style="font-size: 10px"
                    >
                      Line Manager
                    </div>
                    <div
                      class="w-full text-sm uppercase font-semibold text-jet"
                    >
                      {{
                        lineManager
                          ? `${lineManager.fname} ${lineManager.lname}`
                          : "None"
                      }}
                    </div>
                  </div>
                </div>
              </card>
              <card class="p-3 mr-2 ml-5" style="width: 32%; height: 55px">
                <div class="w-full flex h-auto">
                  <icon
                    icon-name="payroll"
                    size="xs"
                    class="mr-1 -mt-7 text-romanSilver"
                  />
                  <div class="w-full flex flex-col h-auto">
                    <div
                      class="uppercase font-semibold text-mediumSeaGreen"
                      style="font-size: 10px"
                    >
                      Earnings
                    </div>
                    <div
                      class="w-full text-lg uppercase font-bold text-darkPurple"
                    >
                      {{ convertToCurrency(employeeData.totalEarnings) }}
                    </div>
                  </div>
                </div>
              </card>
              <card class="p-3 mr-2" style="width: 32%; height: 55px">
                <div class="w-full flex h-auto">
                  <icon
                    icon-name="payroll"
                    size="xs"
                    class="mr-1 -mt-7 text-romanSilver"
                  />
                  <div class="w-full flex flex-col h-auto">
                    <div
                      class="uppercase font-semibold text-desire"
                      style="font-size: 10px"
                    >
                      Deductions
                    </div>
                    <div
                      class="w-full text-lg uppercase font-bold text-darkPurple"
                    >
                      {{ convertToCurrency(employeeData.totalDeductions) }}
                    </div>
                  </div>
                </div>
              </card>
              <card class="p-3" style="width: 32%; height: 55px">
                <div class="w-full flex h-auto">
                  <icon
                    icon-name="icon-expense"
                    size="xs"
                    class="mr-1 -mt-7 text-white"
                  />
                  <div class="w-full flex flex-col h-auto">
                    <div
                      class="uppercase font-semibold"
                      style="font-size: 10px; color: #490166"
                    >
                      Net Variable
                    </div>
                    <div
                      class="w-full text-lg uppercase font-bold text-darkPurple"
                    >
                      {{ convertToCurrency(employeeData.netVariablePay) }}
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </card>
      <div class="mt-6">
        <Tab
          :tabs="['Earnings', 'Non-Statutory Deductions']"
          border
          :active-tab="currentTab"
          @currentTab="currentTab = $event"
          class="pl-5"
        />
      </div>
      <card class="p-5">
        <template v-slot:footer>
          <CardFooter>
            <template v-slot:search>
              <SearchInput
                placeholder="Search"
                class="search"
                style="width: 300px; margin-top: 0.1rem"
              />
            </template>
          </CardFooter>
        </template>
      </card>
      <div>
        <div v-if="!showError">
          <div class="w-full mt-2" style="overflow-x: scroll">
            <EarningsTab
              v-if="currentTab === 'Earnings'"
              :earnings-data="earningData"
              @rowSelected="checkboxes = $event"
            />
            <DeductionsTab
              v-if="currentTab === 'Non-Statutory Deductions'"
              :deductions-data="deductionData"
              @rowSelected="deductionCheckboxes = $event"
            />
          </div>
          <card class="p-5 w-full flex">
            <div class="w-1/6 text-lg font-bold text-darkPurple">Total</div>
            <div
              class="w-5/12 border border-dashed mr-5 mt-4 -ml-28"
              style="height: 1px"
            />
            <div class="flex w-6/12">
              <div class="w-4/12 mt-1">
                <p class="font-black uppercase text-romanSilver text-xs">
                  Total outstanding
                </p>
                <p class="text-lg font-bold text-darkPurple">
                  {{ convertToCurrency(totalEarnings.totalOutstandingAmount) }}
                </p>
              </div>
              <div class="w-4/12 mt-1">
                <p class="font-black uppercase text-romanSilver text-xs">
                  Total earning
                </p>
                <p class="text-lg font-bold text-darkPurple">
                  {{ convertToCurrency(totalEarnings.totalCurrentAmount) }}
                </p>
              </div>
              <div class="w-4/12 mt-1">
                <p class="font-black uppercase text-romanSilver text-xs">
                  Total amount due
                </p>
                <p class="text-lg font-bold text-darkPurple">
                  {{ convertToCurrency(totalEarnings.totalAmountDue) }}
                </p>
              </div>
              <div class="w-3/12 mt-1">
                <p class="font-black uppercase text-romanSilver text-xs">
                  Total addition
                </p>
                <p class="text-lg font-bold text-blueCrayola">
                  {{ convertToCurrency(totalAmount) }}
                </p>
              </div>
            </div>
          </card>
        </div>

        <div v-else>
          <ErrorPage />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Card from "@/components/Card";
import Button from "@/components/Button";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";
import EarningsTab from "./EarningsTab";
import DeductionsTab from "./DeductionsTab";

export default {
  name: "ViewEarnings",
  components: {
    BackButton,
    SearchInput,
    CardFooter,
    ErrorPage,
    Card,
    Button,
    Tab,
    EarningsTab,
    DeductionsTab,
  },
  data() {
    return {
      currentTab: "Earnings",
      page: 1,
      pageCount: 0,
      amount: 0,
      employeeData: {},
      earningData: [],
      deductionCheckboxes: [],
      checkboxes: [],
      showError: false,
      loading: true,
      totalData: [
        "Total Amount",
        "outstandingAmount",
        "currentAmount",
        "amountDue",
        "amount",
      ],
      itemsPerPage: 10,
      lineManager: [],
      totalEarnings: "",
    };
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    totalOutstanding() {
      let result = 0;
      let number;
      this.earningData.forEach((element) => {
        number = parseFloat(element.outstandingVariableAmount);
        result += number;
      });
      return result;
    },
    totalCurrentEarning() {
      let result = 0;
      let number;
      this.earningData.forEach((element) => {
        number = parseFloat(element.currentAdditions);
        result += number;
      });
      return result;
    },
    totalAmount() {
      let result = 0;
      let number;
      let check;
      this.earningData.forEach((element) => {
        number = parseFloat(element.amount);
        result += number;
        check = this.checkboxes.find(
          (item) => item.paySettingId === element.paySettingId
        );
        if (check) {
          this.addToPayroll(check);
        }
      });

      return result;
    },
    totalAmountDue() {
      let result = 0;
      let number;
      this.earningData.forEach((element) => {
        number = parseFloat(element.amountDue);
        result += number;
      });

      return result;
    },
  },
  methods: {
    checkValue(value) {
      const check = this.checkboxes.find(
        (item) => item.paySettingId === value.paySettingId
      );
      if (!check) {
        this.checkboxes.push(value);
      }
    },
    addToPayroll(value) {
      if (value) {
        this.checkboxes.forEach((item) => {
          this.amount += parseFloat(item.amount);
        });
      }
    },
    async processPay() {
      try {
        await this.$handlePrivilege(
          "variableEarnings",
          "addVariableEarningToPayroll"
        );
        if (this.checkboxes.length || this.deductionCheckboxes.length) {
          const variable = {
            addedToPayrunBy: this.$AuthUser.id,
            employeeId: this.$route.params.id,
            month: this.$route.query.month,
            year: this.$route.query.year,
            additions: [],
            deductions: [],
          };

          this.checkboxes.forEach((item) => {
            variable.additions.push({
              paySettingId: item.paySettingId,
              amount: item.amount !== 0 ? item.amount : item.amountDue,
            });
          });
          this.deductionCheckboxes.forEach((item) => {
            variable.deductions.push({
              paySettingId: item?.paySettingId,
              amount: item?.amount !== 0 ? item.amount : item.amountDue,
            });
          });
          try {
            await this.$_saveVariableEarning(variable);
            this.$toasted.success("Value of Payroll net added for processing", {
              duration: 5000,
            });
            this.$router.push({
              name: "VariableEarnings",
              query: { month: variable.month, year: variable.year },
            });
          } catch (error) {
            return this.$toasted.error(
                `${error}`,
                {
                  duration: 6000,
                }
            );
          }
        } else {
          this.$toasted.error(
            "No item selected, please select an item to add to payroll",
            {
              duration: 6000,
            }
          );
        }
      } catch (error) {
        this.$toasted.error(
          "Sorry you do not have privilege to perform this action",
          { duration: 6000 }
        );
      }
    },
    getEmployeeDetails() {
      const payload = {
        month: this.$route.query.month,
        year: this.$route.query.year,
        id: this.$route.params.id,
      };
      this.$_getEmployeeVariablePay(payload).then((result) => {
        this.employeeData = result.data.employee;
        this.earningData = this.employeeData.additions;
        this.deductionData = this.employeeData.deductions;
        this.lineManager = this.employeeData.userReportingTo;
        this.totalEarnings = this.employeeData.additionsSum;
        this.loading = false;
      });
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("variableEarnings", "viewVariablePayList");
      this.showError = false;
    } catch (error) {
      this.showError = true;
    }
    this.getEmployeeDetails();
  },
};
</script>
